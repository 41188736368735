.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    overflow: auto;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
    background-color: white;
    position: absolute;
    top: 20%;
    left: 30%;
    width: 40%;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid black;
}

.close {
    color: Black;
    float: right;
}

.close:hover {
    color: red;
    cursor: pointer;
}
