.grid-container {
    margin-top: 20px;
    display: grid;
    gap: 50px;
    background-color: #5f7161;
    padding: 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    border: 5px solid #6d8b74;
}

.grid-item {
    background-color: #efead8;
    border: 3px solid #efead8;
    padding: 10px 20px 20px;
    text-align: center;
    word-wrap: break-word;
    box-shadow: none;
    font-family: "Dancing Script", cursive;
    font-weight: 400;
    border-radius: 30px;
}

.grid-item:hover {
    box-shadow: 3px 5px #d0c9c0;
    border: 3px double #6d8b74;
}

.show-more {
    text-align: center;
    margin-top: 20px;
}

.iconbox {
    display: inline;
}

.searchbox {
    height: 40px;
    background-color: #fffbf8;
    padding: 5px;
    width: 100%;
    /* margin: 100px auto; */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(72, 72, 72, 0.83),
    0 10px 15px 0 rgba(126, 126, 126, 0.12),
    0 -2px 6px 1px rgba(199, 199, 199, 0.55) inset,
    0 2px 4px 2px rgba(255, 255, 255, 0.83) inset;
    -moz-box-shadow: 0 2px 4px 0 rgba(72, 72, 72, 0.83),
    0 10px 15px 0 rgba(126, 126, 126, 0.12),
    0 -2px 6px 1px rgba(199, 199, 199, 0.55) inset,
    0 2px 4px 2px rgba(255, 255, 255, 0.83) inset;
    box-shadow: 0 2px 4px 0 rgba(72, 72, 72, 0.83),
    0 10px 15px 0 rgba(126, 126, 126, 0.12),
    0 -2px 6px 1px rgba(199, 199, 199, 0.55) inset,
    0 2px 4px 2px rgba(255, 255, 255, 0.83) inset;
}
input[type="search"] {
    display: inline;
    /* margin: 0 20px; */
    width: 90%;
    height: 30px;
    padding-left: 15px;
    border-radius: 6px;
    border: none;
    color: #939393;
    font-weight: 500;
    background-color: #fffbf8;
    -webkit-box-shadow: 0 -2px 2px 0 rgba(199, 199, 199, 0.55), 0 1px 1px 0 #fff,
    0 2px 2px 1px #fafafa, 0 2px 4px 0 #b2b2b2 inset, 0 -1px 1px 0 #f2f2f2 inset,
    0 15px 15px 0 rgba(41, 41, 41, 0.09) inset;
    -moz-box-shadow: 0 -2px 2px 0 rgba(199, 199, 199, 0.55), 0 1px 1px 0 #fff,
    0 2px 2px 1px #fafafa, 0 2px 4px 0 #b2b2b2 inset, 0 -1px 1px 0 #f2f2f2 inset,
    0 15px 15px 0 rgba(41, 41, 41, 0.09) inset;
    box-shadow: 0 -2px 2px 0 rgba(199, 199, 199, 0.55), 0 1px 1px 0 #fff,
    0 2px 2px 1px #fafafa, 0 2px 4px 0 #b2b2b2 inset, 0 -1px 1px 0 #f2f2f2 inset,
    0 15px 15px 0 rgba(41, 41, 41, 0.09) inset;
}

img {
    width: 100%;
    height: auto;
}

.filter-container {
    text-align: left;
    float: left;
}

.filter-container > p {
    margin-top: 0;
}

.edit {
    float: left;
}

.edit:hover {
    color: blue;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .grid-container {
        grid-template-columns: auto;
    }
}