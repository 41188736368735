button {
    background: #576e86;
    border: none;
    padding: 8px 10px 8px 10px;
    border-radius: 5px;
    color: #a8bace;
    margin: 0 5%;
    font-family: "Montserrat", sans-serif;
}

button:hover,
input[type="submit"]:hover {
    background: #394d61;
}

.button-div {
    text-align: center;
    margin: 10px auto;
}

.note {
    text-align: left;
    color: #a8bace;
    font-size: 12px;
    line-height: 1;
}

.recipe-form {
    width: 80%;
    font-size: 16px;
    background: #495c70;
    padding: 30px 30px 15px 30px;
    border: 5px solid #53687e;
    margin: 0;
    line-height: 3;
    display: inline-block;
}

.recipe-form label {
    display: block;
    margin-bottom: 10px;
    color: #fff;
}
.recipe-form label > span {
    float: left;
    width: 140px;
    text-align: left;
}
.recipe-form input[type="text"] {
    background: transparent;
    border: none;
    border-bottom: 1px dashed #83a4c5;
    width: 60%;
    outline: none;
    color: #fff;
    font-family: "Montserrat", sans-serif;
}

.recipe-form textarea {
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px dashed #83a4c5;
    width: 60%;
    overflow: auto;
    resize: vertical;
    height: auto;
    color: #fff;
    font-family: "Montserrat", sans-serif;
}

.recipe-form textarea:focus,
.recipe-form input[type="text"]:focus {
    border-bottom: 1px dashed #d9ffa9;
}

@media screen and (max-width: 600px) {
    .recipe-form input[type="text"],
    .recipe-form textarea {
        width: 100%;
    }
}